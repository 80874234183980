<template>
	<div class="Home" style="min-width: 1900px;">
		<el-container>
			<el-header style="background-color: #FFFFFF;">
				<div class="header">
					<img style="width: 250px;" src="../assets/home/homeLogo.png" />

					<div style="display: flex; width: 200px;justify-content: space-between;font-size: 30px;">
						会员资质管理
					</div>
				</div>
			</el-header>
			<el-main>
				<div style="display: flex;justify-content: flex-end;margin-right: 300px;">
					<el-button class="item2" style="margin-top: 20px;" type="primary" @click="show_point">积分记录
					</el-button>
					<el-button class="item2" style="margin-top: 20px;" type="primary" @click="show_pay">付款记录
					</el-button>
				</div>

				<div class="main">

					<div style="width: 100%;margin-top: 20px;">
						<div v-if="vip==1" style="font-size: 20px;  color: green">
							当前VIP账号到期时间：{{endtime}}
						</div>
						<div v-else style="font-size: 20px;  color: red">
							当前账号未加入VIP
						</div>
						<div
							style="display: flex; justify-content: center;margin-top: 30px;width: 100%;font-size: 20px;font-weight: bold;">
							充值年限选择
						</div>
						<div>
							<el-radio-group v-model="pay_year" @change="pay_year_change"
								style="font-size: 40px;margin-top: 40px;">
								<el-radio :label="1" style="font-size: 20px;">一年</el-radio>
								<el-radio :label="2">二年8折</el-radio>
								<el-radio :label="3">终身使用</el-radio>
							</el-radio-group>
						</div>
						<div style="margin-top: 40px;display: flex;justify-content: center;font-size: 20px;">
							会员积分{{core}}分，本次可使用{{core_use}}分
						</div>
						<div style="margin-top: 20px;display: flex;justify-content: center;font-size: 30px;">
							应付{{price + " 元"}}，积分抵扣{{core_use/2}}元
						</div>
						<div style="margin-top: 40px;display: flex;justify-content: center;font-size: 20px;color: red;">
							实际支付
						</div>
						<div
							style="margin-top: 20px;display: flex;justify-content: center;font-size: 40px;font-weight: bold;">
							{{price_core}}元
						</div>
						<div
							style="margin-top: 40px;display: flex;justify-content: center;font-size: 40px;font-weight: bold;">
							<el-button class="item1" type="success" @click="pay_set(0)">微信支付</el-button>
						</div>
						<!-- <div
							style="margin-top: 40px;display: flex;justify-content: center;font-size: 40px;font-weight: bold;">
							<el-button class="item1" type="primary" @click="pay_set(1)">支付宝支付</el-button>
						</div> -->
						<div
							style="margin-top: 40px;margin-bottom: 40px; display: flex;justify-content: center;font-size: 20px;color: green">
							积分使用规则：1个会员积分抵扣0.5元，累计总额不超过原价的50%
						</div>

					</div>

				</div>

			</el-main>
			<el-footer>
				<div class="footer">
					<div style="display: flex; flex-direction: column;">
						<img style="width: 150px;" src="../assets/home/gongzhonghao.jpg" />
						<span style="color: #FFFFFF;">加入智库</span>
					</div>
					<div
						style="display: flex;flex-direction: column; align-items: flex-start; margin-left: 100px; height: 80px;justify-content: space-between;">
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/danwei.png" />
							<div style="color: #FFFFFF;">主办单位：应急安全智库平台</div>
						</div>
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/kefu1.png" />
							<div style="color: #FFFFFF;">客服微信：anquan0311</div>
						</div>
					</div>
				</div>
			</el-footer>
		</el-container>
		<el-dialog class="css-el-dialog" title="请用微信扫码支付" :visible.sync="payshow" width="20%"
			:close-on-click-modal="false" @close="end_pay">
			<el-image :src="wx_url">
			</el-image>
		</el-dialog>
		<el-dialog class="css-el-dialog" title="付款记录" :visible.sync="pay_history_show" width="40%"
			:close-on-click-modal="false">
			<el-table :stripe='true' ref="singleTable" :data="paytableData"
				:header-cell-style="{ backgroundColor: '#EBEEF5' }" highlight-current-row
				style="width: 100%;min-height: 500px;">
				<el-table-column type="index" align="center" width="100" label="序号">
				</el-table-column>
				<el-table-column property="created_at" label="付款日期" align="center">
				</el-table-column>
				<el-table-column property="total_money" label="付款金额(元)" align="center" width="150">
					<template slot-scope="scope">
						{{scope.row.total_money/100}}
					</template>
				</el-table-column>
				<el-table-column property="pay_year" align="center" width="150" label="会员年限">
					<template slot-scope="scope">
						{{scope.row.pay_year==3?"终身使用":scope.row.pay_year +"年"}}
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog class="css-el-dialog" title="积分记录" :visible.sync="point_history_show" width="40%"
			:close-on-click-modal="false">
			<div style="width: 100%;justify-content: flex-end;display: flex;margin-bottom: 20px;">
				<el-radio-group v-model="get_send" size="small" @change="radioChange">
					<el-radio-button label="0">获得积分情况</el-radio-button>
					<el-radio-button label="1">帮助好友情况</el-radio-button>
				</el-radio-group>
			</div>

			<el-table :stripe='true' ref="singleTable" :data="pointtableData"
				:header-cell-style="{ backgroundColor: '#EBEEF5' }" highlight-current-row
				style="width: 100%;min-height: 500px;">
				<el-table-column type="index" align="center" width="100" label="序号">
				</el-table-column>
				<el-table-column property="created_at" label="日期" align="center">
				</el-table-column>
				<el-table-column property="integral" label="分数" align="center" width="150">
				</el-table-column>
				<el-table-column property="pay_year" align="center" width="150" label="事件">
					<template slot-scope="scope">
						<div v-if="scope.row.type==1" style="margin: 20rpx;">好友点击分享链接</div>
						<div v-if="scope.row.type==2" style="margin: 20rpx;">好友完善会员资料</div>
						<div v-if="scope.row.type==3" style="margin: 20rpx;">好友购买VIP会员</div>
						<div v-if="scope.row.type==4" style="margin: 20rpx;">积分抵扣现金支付</div>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog class="css-el-dialog" title="支付宝支付" :visible.sync="zfb_show" width="40%"
			:close-on-click-modal="false">


		</el-dialog>
	</div>
</template>

<script>
	import {
		request
	} from "../network/request.js";
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Home',
		data() {
			return {
				user_id: this.$store.state.user_id,
				vip: localStorage.getItem('vip'),
				pay_year: 1, //支付年限
				core: 0, //会员积分
				openid: this.$store.state.user_openid,
				price: 99, //单位是分，发起支付的时候要乘以100
				endtime: localStorage.getItem('user_stopat') == '2030-12-31' ? '终身使用' : localStorage.getItem(
					'user_stopat'), //会员期限
				core_use: 0,
				price_core: 0,
				wx_url: '', //微信付款码地址
				payshow: false,
				timer: "",
				pay_history_show: false,
				paytableData: [],
				point_history_show: false,
				pointtableData: [],
				get_send: 0,
				zfb_show: false, //支付宝支付弹窗开关
				zfb_pay: ''
			}
		},
		created() {
			// this.getcore();
			//初始化付款参数
			this.getponits(this.user_id);
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
		methods: {
			radioChange(e) {
				//切换
				let that = this;
				if (that.get_send == "0") {
					// console.log(111);
					request({
							url: 'datum/integral/list',
							method: 'post',
							data: {
								datum_user_id: this.user_id
							}
						})
						.then((res) => {
							console.log(res)
							this.pointtableData = res.data
						})
				} else {
					request({
							url: 'datum/integral/list',
							method: 'post',
							data: {
								initiator_id: this.user_id
							}
						})
						.then((res) => {
							console.log(res)
							this.pointtableData = res.data
						})
				};
			},
			end_pay() {
				clearInterval(this.timer);
			},
			show_point() { //显示积分记录
				this.point_history_show = true;
				request({
						url: 'datum/integral/list',
						method: 'post',
						data: {
							datum_user_id: this.user_id
						}
					})
					.then((res) => {
						console.log(res)
						this.pointtableData = res.data
					})
			},
			show_pay() { //显示支付记录
				this.pay_history_show = true;
				request({
						url: 'datum/order_list',
						method: 'post',
						data: {
							user_id: this.user_id,
						}
					})
					.then((res) => {
						console.log(res)
						this.paytableData = res.data
					})
			},
			getcore() { //获取会员积分数量
				request({
						url: 'datum/integral/deduction',
						method: 'post',
						data: {
							datum_user_id: this.user_id
						}
					})
					.then((res) => {
						console.log("resssss", res)
						this.core = res.data.integral
					})
			},
			getponits(user_id) { //获取积分总数
				request({
						url: 'datum/integral/deduction',
						method: 'post',
						data: {
							datum_user_id: this.user_id
						}
					})
					.then((res) => {
						console.log("resssss", res)
						this.core = res.data.integral
						//初始化付款参数
						let price_temp = 99;
						let core_use_temp = 99;
						let price_core_temp = 0;
						// console.log("this.data.core", this.data.core);

						if (this.core <= core_use_temp) {
							core_use_temp = this.core;
						}
						price_core_temp = 99 - (core_use_temp / 2);
						console.log(111, price_temp, core_use_temp, price_core_temp);
						this.price = price_temp;
						this.core_use = core_use_temp;
						this.price_core = price_core_temp;
						console.log(222, this.price, this.core_use, this.price_core);
					});
			},
			pay_year_change() {

				let price_temp = 99;
				let core_use_temp = 0;
				let price_core_temp = 0;

				switch (this.pay_year) {
					case 1:
						console.log(111)
						price_temp = 99;
						core_use_temp = 49.5 * 2;
						if (this.core < core_use_temp) {
							core_use_temp = this.core;
						}
						price_core_temp = 99 - (core_use_temp / 2);
						break;
					case 2:
						console.log(222)
						price_temp = 179;
						core_use_temp = 89.5 * 2;
						if (this.core < core_use_temp) {
							core_use_temp = this.core;
						}
						price_core_temp = 179 - (core_use_temp / 2);
						break;
					case 3:
						console.log(333)
						price_temp = 299;
						core_use_temp = 149.5 * 2;
						if (this.core < core_use_temp) {
							core_use_temp = this.core;
						}
						price_core_temp = 299 - (core_use_temp / 2);
						break;
				};
				this.price = price_temp;
				this.core_use = core_use_temp;
				this.price_core = price_core_temp;
			},
			points_add(initiator_id, datum_user_id, type, integral) { //判断并添加分享积分
				//积分赠送发起人（送分的）id：initiator_id,当前登录用户（收分的）id：datum_user_id;type字段   1：点击赠送；2：注册赠送；3：购买赠送；4：消费抵扣
				request({
						url: 'datum/integral/award',
						method: 'post',
						data: {
							initiator_id: initiator_id,
							datum_user_id: datum_user_id,
							type: type,
							integral: integral
						}
					})
					.then((res) => {
						// console.log("增加分析分数一次");
						this.getponits(this.user_id)
					});
			},
			pay_set(type) {
				// console.log(11111111, this.data.endtime);
				let now = new Date();
				let temp_time = new Date(this.endtime);
				// console.log(11111111, now, 22222222222222, temp_time);
				if (now > temp_time) {
					temp_time = now
				}
				let pay_year_temp = this.pay_year;
				let temp_endtiem = '';
				if (pay_year_temp == '3') {
					temp_endtiem = '2030-12-31';
				} else {
					let temp_y = +temp_time.getFullYear() + Number(this.pay_year);
					let temp_m = (temp_time.getMonth() + 1).toString().padStart(2, '0');
					let temp_d = temp_time.getDate().toString().padStart(2, '0');
					temp_endtiem = temp_y + "-" + temp_m + "-" + temp_d;
				};
				if (type == 0) { //微信支付
					request({
							url: 'datum/native_pay',
							method: 'post',
							data: {
								user_id: this.user_id,
								total_money: this.price_core * 100, //单位分
								// total_money: this.price_core,
								pay_year: pay_year_temp,
								vip_end_time: temp_endtiem,
								integral: 0 - this.core_use
							}
						})
						.then((res) => {
							console.log("ure", res)
							this.wx_url = "http://www.szdunan.cn/" + res.data.code_url;
							this.payshow = true;
							//开始轮询支付结果
							this.timer = setInterval(() => {
								request({
										url: 'datum/order_list',
										method: 'post',
										data: {
											user_id: this.user_id,
										}
									})
									.then((res) => {
										console.log(res.data);
										if (res.data.length > 0) {
											let pay_time = new Date(res.data[0].created_at);
											let now_time = new Date();
											if (now_time - pay_time < 30000) {
												clearInterval(this.timer);
												this.payshow = false;
												this.$alert('支付成功！', '提示', {
													confirmButtonText: '确定',
												});
												this.points_add(this.user_id, '', 3, 20) //给其他人增加积分
												console.log("统一下单接口成功");
												this.vip = 1;
												localStorage.setItem('vip', 1);
												this.endtime = temp_endtiem;
												localStorage.setItem('user_stopat', temp_endtiem);
											}
										}
									});
							}, 1000);
						})
				} else if (type == 1) { //支付宝支付
					request({
							url: 'datum/ali_pay',
							method: 'post',
							data: {
								user_id: this.user_id,
								total_money: this.price_core, //单位元
								// total_money: 0.01,
								pay_year: pay_year_temp,
								vip_end_time: temp_endtiem,
								integral: 0 - this.core_use
							}
						})
						.then((res) => {
							localStorage.setItem('zfb_pay_code', res)
							const {
								href
							} = this.$router.resolve({
								path: "/pay_zfb",
							});
							window.open(href, '_blank');
							this.timer = setInterval(() => {
								request({
										url: 'datum/order_list',
										method: 'post',
										data: {
											user_id: this.user_id,
										}
									})
									.then((res) => {
										console.log(res.data);
										if (res.data.length > 0) {
											let pay_time = new Date(res.data[0].created_at);
											let now_time = new Date();
											if (now_time - pay_time < 30000) {
												clearInterval(this.timer);
												this.payshow = false;
												this.$alert('支付成功！', '提示', {
													confirmButtonText: '确定',
												});
												this.points_add(this.user_id, '', 3, 20) //给其他人增加积分
												console.log("统一下单接口成功");
												this.vip = 1;
												localStorage.setItem('vip', 1);
												this.endtime = temp_endtiem;
												localStorage.setItem('user_stopat', temp_endtiem);
											}
										}
									});
							}, 1000);
						})
				}

			},

		},

		computed: {

		}

	}
</script>

<style lang="less" scoped>
	.el-radio /deep/ .el-radio__label {

		font-size: 20px !important;

	}

	.header {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-around;
		border-bottom: 1px solid #2C3E50;

	}

	* {
		padding: 0;
	}


	.footer {
		height: 250px;
		margin-left: -1000px;
		background-color: #033B6B;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.popover_flex {
		display: flex;
		align-items: center;

	}

	.main {
		margin-top: 10px;
		min-height: 615px;
	}

	.item1 {
		width: 200px;
		height: 40px;
		font-size: 20px;
	}

	.item2 {
		width: 150px;
		height: 40px;
		font-size: 20px;
		background-color: #40c200;
		border: green;
	}

	.bottom_flex {
		display: flex;
		align-items: center;
	}
</style>
<style scoped>
	/* 弹窗统一样式更改 */
	.css-el-dialog>>>.el-dialog__header {
		background-color: #55aaff;
		padding: 10px 20px;
		color: #fff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.css-el-dialog>>>.el-dialog {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.css-el-dialog>>>.el-dialog__title {
		color: #fff;
		font-weight: bold;
		font-size: 16px;
		letter-spacing: 3px;
	}

	.css-el-dialog>>>.el-dialog__close {
		position: relative;
		top: -7px;
		color: #fff;
		font-size: 20px;
	}

	.css-el-dialog>>>.el-dialog__close:hover {
		color: #fff;
		font-size: 20px;
	}

	.css-el-dialog>>>.el-dialog__body {
		padding: 20px;
		max-height: 600px;
		overflow-y: scroll;
	}

	.css-display-inline-block {
		display: inline-block;
	}

	.css-flex {
		display: flex;
	}

	.css-width-30 {
		width: 30%;
		margin-top: 2%;
	}

	.css-flex-direction-column {
		display: flex;
		flex-direction: column;
	}

	.css-flex-justify-content-center {
		display: flex;
		justify-content: center;
	}

	.css-flex-align-items-center {
		display: flex;
		align-items: center;
	}

	.css-justify-content-space-between {
		display: flex;
		justify-content: space-between;
	}
</style>